<template>
  <div>
    <div class="search-div">
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="">
          <a-form-item label="">
            <a-select
              v-model:value="searchForm.orgId"
              placeholder="业务部门"
              style="width: 180px"
            >
              <a-select-option v-for="item in $store.state.app.orgBusList" :key="item.id" :value="item.id"
                               @click="orgChange(item.id)">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-form-item>
        <a-form-item label="业务员">
          <a-select
            v-model:value="searchForm.saleName"
            :filter-option="false"
            show-search
            placeholder="请输入员工姓名,按下回车搜索"
            @search="employerValue"
            style="width: 300px;"
          >
            <template v-if="flagLoading" #notFoundContent>
              <a-spin size="small" />
            </template>
            <a-select-option
              v-for="item in employeeOptions"
              :key="item.id"
              :value="item.name"
              @click="employeeChoose(item)" >{{ item.name }}-{{ item.no }}-{{ item.orgName }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-space>
            <a-button type="primary"  :loading="loading" @click="searchBtn">搜索</a-button>
            <a-button :loading="loading" @click="resetBtn">重置</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </div>
    <div class="private-intention">
      <div class="title">
        <span>合作等级统计</span>
      </div>
      <div class="data-statistics">
        <div class="data-show" v-for="(item, index) in gradeList" :key="index">
          <a-popover v-if="index!=0">
            <template  v-if="item.type?.describe" #content>
              <p style="width: 300px; padding: 0 10px">{{item.type?.describe}}</p>
            </template>
            <div>
              <div class="number-title">{{ item.type?.label }}</div>
              <div style="margin-left:22px" class="number-show">{{ item.num }}</div>
              <div style="margin-left:22px"><div v-if="item.proportion">占比 {{ item.proportion }}%</div></div>
            </div>
          </a-popover>
          <div v-else>
            <div class="number-title">{{ item.type?.label }}</div>
              <div style="margin-left:22px" class="number-show">{{ item.num }}</div>
              <div style="margin-left:22px"><div v-if="item.proportion">占比 {{ item.proportion }}%</div></div>
          </div>
        </div>
      </div>
    </div>
    <div style="display:flex">
      <div style="
          width: 400px;
          display: flex;
          flex-direction: column;
          background: #fff;
          margin-top: 10px;
          padding: 10px;
        " >
        <div class="title">
          <span>合作客户预警</span>
        </div>
        <div style="width: 100%" v-for="(item, index) in warningRemindList" :key="index">
          <div style="position: relative; width: 100%; margin-top: 25px">
            <div>
              <div>{{item.type.label}}</div>
              <div style="font-weight: bold; font-size: 36px; color: #344563">{{item.num}}</div>
            </div>
            <div class="img-div">
<!--              <img :src="`@/assets\\kanBan/W${index}.png`" style="width: 70px; height: 70px" />-->
              <img :src="imgs[index]" style="width: 70px; height: 70px" />
            </div>
          </div>
          <a style="font-size: 18px;cursor: pointer" v-if="item.num > 0" @click="openDetail(item)">点击查看</a>
          <a-divider />
        </div>
      </div>
      <div class="num-profit-div">
        <div class="title">
          <span>合作客户台量及利润</span>
        </div>
        <div class="content-div">
          <div class="data-statistics" style="display: flex; justify-content: space-between" v-for="(item, index) in vehicleGrossList" :key="index">
            <div >
              <div class="data-label">{{ item.type.label }}</div>
              <div class="data-num">{{ item.num }}</div>
            </div>
            <div v-if="item.vehicles" class="data-statistics-right" >
              <div class="data-vehicle">
                {{ item.vehicles }} 台
              </div>
              <div class="data-gross">
                {{ item.gross }} 元
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ranking-div">
        <div class="title">
          <span>合作客户发货量(前20名)</span>
        </div>
        <div style="margin-top:10px">
          <a-table size="small" :columns="columns" :row-key="record => record.rank" :data-source="orderList" bordered  :loading="loading" @change="handleTableChange">
          </a-table>
        </div>
      </div>
    </div>
  </div>
  <a-modal v-model:visible="detailShow" width="80%" :title="detailTitle" footer="" :getContainer="false"
           :bodyStyle="{ paddingTop: '10px' }">
    <RemindDetail ref="remindDetail" :remind="remindType" :orgId="orgId" :customerClass="customerClass"/>
  </a-modal>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import { coopKanBan } from '@/api/dataCenter/kanban'
import { employeeList } from '@/api/crmManagement/setRules'
import RemindDetail from '../privateSeaKanBan/RemindDetail'
export default {
  components: {
    RemindDetail
  },
  setup () {
    const remindDetail = ref(null)
    const state = reactive({
      searchForm: {
        time: '',
        loading: false,
        department: null,
        salesman: ''
      },
      loading: false,
      flagLoading: false,
      detailShow: false,
      resultData: {},
      detailTitle: undefined,
      remindType: undefined,
      orgId: undefined,
      customerClass: undefined,
      gradeList: [],
      imgs: [
        require('@/assets/kanBan/W0.png'),
        require('@/assets/kanBan/C0.png'),
        require('@/assets/kanBan/C1.png')
      ],
      warningRemindList: [],
      vehicleGrossList: [],
      orderList: [],
      timer: null,
      employeeOptions: [],
      columns: [
        {
          title: '排名',
          dataIndex: 'rank',
          align: 'center'
        },
        {
          title: '客户名称',
          dataIndex: 'customerName',
          align: 'center'
        },
        {
          title: '业务员',
          dataIndex: 'salesman',
          align: 'center'
        },
        {
          title: '合作时间',
          dataIndex: 'time',
          align: 'center'
        },
        {
          title: '下单台量',
          dataIndex: 'vehicles',
          align: 'center'
        },
        {
          title: '总毛利',
          dataIndex: 'gross',
          align: 'center'
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      loadData()
    })

    const searchBtn = () => {
      loadData()
    }
    const loadData = () => {
      state.loading = true
      coopKanBan({
        ...state.searchForm
      }).then(res => {
        state.orderList = []
        if (res.code === 10000) {
          state.resultData = res.data
          state.gradeList = res.data.gradeList
          state.warningRemindList = res.data.warningRemindList
          state.vehicleGrossList = res.data.vehicleGrossList
          state.orderList = res.data.orderList
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const handleTableChange = page => {
      state.pagination = page
    }
    // 员工搜索
    const employerValue = (e) => {
      if (e === '' || e === null) {
        clearTimeout(state.timer)
        state.fetching = false
      }
      state.flagLoading = true
      if (state.timer !== null) {
        clearTimeout(state.timer)
      }
      state.timer = setTimeout(() => {
        employeeList({
          current: 1,
          name: e,
          size: 10
        })
          .then((res) => {
            if (res.code === 10000) {
              state.employeeOptions = res.data
              state.flagLoading = false
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }, 1000)
    }
    const resetBtn = () => {
      state.searchForm.saleId = null
      state.searchForm.saleName = undefined
      state.searchForm.orgId = null
      searchBtn()
    }
    // 选择员工
    const employeeChoose = (item) => {
      state.searchForm.saleId = item.id
      state.searchForm.saleName = item.name + ' - ' + item.orgName
      state.employeeOptions = []
    }

    const openDetail = (item) => {
      if (item.num > 0) {
        state.detailShow = true
        state.detailTitle = item.type.label
        state.remindType = item.type
        state.orgId = state.searchForm.orgId
        state.customerClass = 3
        setTimeout(() => {
          remindDetail.value.loadData(1)
        }, 10)
      }
    }

    return {
      ...toRefs(state),
      handleTableChange,
      employeeChoose,
      remindDetail,
      employerValue,
      resetBtn,
      openDetail,
      searchBtn
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
